<template>
    <div class="box">
        <div class="box_content">
            <div class="tabbar">
                <div v-for="(item, index) in tabData" :key="index" @click="changeTab(index, item)"
                     :class="tab === index ? 'onbar' : 'bar'">
                    <div class="popup_box">
                        <span>{{ item.label }}</span>
                    </div>
                </div>
            </div>
            <div class="login" v-if="show_login_info && show_login_btn" style="cursor: pointer;">
                <div @click="start_login">登录/注册</div>
            </div>
            <div :class="tab === 8 ? 'onbar login' : 'bar login'" v-if="!show_login_btn" style="cursor: pointer;">
                <el-icon size="30" @click="go_personal">
                    <Avatar :color="'#999999'"/>
                </el-icon>
                <span @click="go_personal">{{ base_info.name }}</span>
                <span>丨</span>
                <div @click="logout">退出</div>
            </div>
        </div>
        <Login :loginDialog="loginDialog" :can_send_code="can_send_code" @change_province="change_province"
               @bind_phone="show_bind_phone" @add_base_info="add_base_info_fun" @show_huadong_fun="show_huadong_fun"/>
        <bind_phone :show_bind_phone_status="show_bind_phone_status" @change_user_phone="change_user_phone"
                    @add_base_info="add_base_info_fun"></bind_phone>
    </div>
</template>

<script>
import Login from "./login.vue";
import Bind_phone from "@/views/personal/comps/bind_phone.vue";
import axios from "axios";
import api from "@/api";
import {ElMessageBox} from "element-plus";
import Check_huadong from "@/layout/comps/check_huadong.vue";
import env from "@/utils/env";
import {useInfoStore} from "@/store";

export default {
    components: {
        Check_huadong,
        Bind_phone,
        Login,
    },
    props: {
        show_login: {
            type: Number,
            default: 0
        },
        show_bind_phone: {
            type: Number,
            default: 0
        },
        show_user_info: {
            type: Number,
            default: 0
        },
        add_base_info: {
            type: Function,
            default: null
        }
    },
    data() {
        return {
            show_login_btn: true,
            show_login_info: true,
            show_bind_phone_status: false,
            show_check_huadong: 0,
            can_send_code: 0,
            tab: 0,
            base_info: {},
            oss_url_list: {},
            tabData: [
                {
                    label: "首页",
                    path: "/",
                },
                {
                    label: "高考资讯",
                    path: "/message",
                },
                {
                    label: "高考工具箱",
                    path: "/tools",
                },
                {
                    label: "高一选科",
                    path: "/subject_analysis",
                },
                {
                    label: "AI选专业",
                    path: "/assess_info",
                },
                {
                    label: "智能填志愿",
                    path: "/volunteer",
                },
                {
                    label: "我的志愿",
                    path: "/intention",
                },
            ],
            loginDialog: {
                show: false,
            },
            province_url_list: [],
            version: 409233234,
        };
    },
    watch: {
        show_login(new_val) {
            this.loginDialog.show = new_val > 0;
            this.get_user_info()
        },
        show_bind_phone(num) {
            if (num > 0) {
                this.show_bind_phone_status = true
            }
        },
        '$route'(to, from) {
            let time = localStorage.getItem('over_time') || 0
            let base_info = localStorage.getItem('base_info')
            
            let need_login = false
            if (base_info !== null && base_info !== 'null' && base_info !== '[]' && base_info !== '' && base_info !== 'undefined') {
            } else {
                need_login = true
            }
            
            if (need_login || Date.now() / 1000 - time > 360) {
                if (to.path !== '/news' && to.path !== '/tools' && to.fullPath !== '/home' && to.fullPath !== '/') {
                    ElMessageBox.alert('请登录后查看', '提醒', {
                        confirmButtonClass: 'confirm_button_class',
                        confirmButtonText: '好的',
                        callback: () => {
                            this.loginDialog.show = true
                            this.show_login_btn = true
                            this.$router.push({path: '/', query: {}})
                        },
                    })
                }
            }
            
            let url = to.path
            if (url.indexOf('home_page') > -1 || url.indexOf('intention') > -1) {
                this.tab = 6
            } else if (url.indexOf('assess_info') > -1) {
                this.tab = 4
            } else if (url.indexOf('subject_analysis') > -1) {
                this.tab = 3
            } else if (url.indexOf('chose_batch') > -1 || url.indexOf('chose_school') > -1) {
                this.tab = 5
            } else if (url.indexOf('personal') > -1) {
                this.tab = 8
            } else if (url.indexOf('tools') > -1) {
                this.tab = 2
            } else if (url.indexOf('news') > -1 || url.indexOf('article_info') > -1) {
                this.tab = 1
            } else if (url.indexOf('/home') > -1 || url.indexOf('/') > -1) {
                this.tab = 0
            }
        }
    },
    updated() {
        if (window.location.host.indexOf('teacher-login') > -1) {
            this.show_login_info = false
        }
    },
    created() {
        let local = new URL(window.location.href)
        axios.get(
            'https://oss.shudaozaixian.com/cache/agent_cms_info/list/' + local.hostname + '.json?time=' + new Date().getTime(), {}
        ).then(function (res) {
            if (res.data.domain) {
                let favicon = document.querySelector('link[rel="icon"]');
                if (favicon !== null) {
                    favicon.href = 'https://oss.shudaozaixian.com/cache/favicon/' + res.data.domain + '.ico';
                } else {
                    favicon = document.createElement("link");
                    favicon.rel = "icon";
                    favicon.href = 'https://oss.shudaozaixian.com/cache/favicon/' + res.data.domain + '.ico';
                    document.head.appendChild(favicon);
                }
            }
        }).catch(function (err) {
        });
        
        if (window.location.host.indexOf('teacher-login') > -1) {
            this.show_login_info = false
        }
        let url = window.location.href
        if (url.indexOf('home_page') > -1 || url.indexOf('intention') > -1) {
            this.tab = 6
        } else if (url.indexOf('assess_info') > -1) {
            this.tab = 4
        } else if (url.indexOf('subject_analysis') > -1) {
            this.tab = 3
        } else if (url.indexOf('chose_batch') > -1 || url.indexOf('chose_school') > -1) {
            this.tab = 5
        } else if (url.indexOf('personal') > -1) {
            this.tab = 8
        } else if (url.indexOf('tools') > -1) {
            this.tab = 2
        } else if (url.indexOf('news') > -1 || url.indexOf('article_info') > -1) {
            this.tab = 1
        } else if (url.indexOf('/home') > -1 || url.indexOf('/') > -1) {
            this.tab = 0
        }
        
        this.check_version()
        this.get_user_info()
        this.get_oss_list()
    },
    methods: {
        check_version() {
            let that = this
            axios.get(
                api.oss.version + '?t=' + (Date.now() + '').substring(0, 10), {}
            ).then(function (res) {
                if (res.status === 200) {
                    if (res.data.v > that.version) {
                        location.reload(true)
                    }
                }
            }).catch(function (err) {
            });
        }, get_oss_list() {
            let that = this
            axios.get(
                api.oss.list + '?t=' + (Date.now() + ''), {}
            ).then(function (res) {
                that.oss_url_list = res.data
                that.get_province_url()
            }).catch(function (err) {
            });
        },
        get_province_url() {
            let that = this
            let province_id = localStorage.getItem('province_id') || 0
            axios.get(
                that.oss_url_list.province_url, {}
            ).then(function (res) {
                that.province_url_list = res.data
                let local_url = that.province_url_list[province_id] || null
                if (local_url !== null) {
                    axios.defaults.baseURL = local_url
                } else if (that.province_url_list[0] !== undefined) {
                    axios.defaults.baseURL = that.province_url_list[0]
                } else {
                    axios.defaults.baseURL = env[process.env.NODE_ENV].API
                }
                
                //console.log(axios.defaults.baseURL)
                
                localStorage.setItem('province_url_list', JSON.stringify(that.province_url_list))
            }).catch(function (err) {
            });
        },
        start_login() {
            this.loginDialog.show = true
            let chose_province_id = localStorage.getItem('chose_province_id')
            let chose_province_name = localStorage.getItem('chose_province_name')
            let user_agreement = localStorage.getItem('user_agreement')
            localStorage.clear()
            localStorage.setItem('chose_province_id', chose_province_id)
            localStorage.setItem('chose_province_name', chose_province_name)
            localStorage.setItem('user_agreement', user_agreement)
            
            if (this.province_url_list[0] !== undefined) {
                axios.defaults.baseURL = this.province_url_list[0]
            } else {
                axios.defaults.baseURL = env[process.env.NODE_ENV].API
            }
        },
        register_fun() {
        
        },
        show_huadong_fun() {
            //console.log('-----show_huadong_fun-------')
            this.show_check_huadong += 1
        },
        check_huadong_success() {
            //console.log('-----check_huadong_success-------')
            this.can_send_code += 1
        },
        add_base_info_fun() {
            this.$emit('add_base_info')
        },
        change_user_phone() {
            this.get_user_info()
        },
        show_bind_phone() {
            this.show_bind_phone_status = true
        },
        get_user_info() {
            let base_info = localStorage.getItem('base_info') || ''
            if (base_info !== null && base_info !== 'null' && base_info !== '[]' && base_info !== '' && base_info !== 'undefined') {
                this.base_info = JSON.parse(base_info)
                this.show_login_btn = false
                this.$emit('change_province')
            } else {
                this.show_login_btn = true
            }
        },
        change_province(info) {
            this.loginDialog.show = false
            this.get_user_info()
            this.$emit('change_province')
        },
        go_personal() {
            this.$router.push({path: '/personal'})
        },
        logout() {
            if (this.province_url_list[0] !== undefined) {
                axios.defaults.baseURL = this.province_url_list[0]
            } else {
                axios.defaults.baseURL = env[process.env.NODE_ENV].API
            }
            
            localStorage.clear()
            this.get_user_info()
            this.$emit('change_province')
            useInfoStore().needReSetScore()
            this.$router.push({path: '/home'})
        },
        changeTab(index, item) {
            let path = item.path
            
            let label = item.label
            if (label === '智能填志愿') {
                let base_info = localStorage.getItem('base_info') || ''
                if (base_info !== null && base_info !== 'null' && base_info !== '[]' && base_info !== '' && base_info !== 'undefined') {
                    if (base_info.subject_one < 1 || base_info.subject_three < 1 || base_info.subject_two < 1 || base_info.score < 1 || base_info.precedence < 1) {
                        ElMessageBox.alert('请填写选科及分数信息', '提醒', {
                            confirmButtonClass: 'confirm_button_class',
                            confirmButtonText: '好的',
                            callback: () => {
                                this.$router.push({path: '/'})
                            },
                        })
                    }
                } else {
                    // ElMessageBox.alert('请登陆', '提醒', {
                    //     confirmButtonClass: 'confirm_button_class',
                    //     confirmButtonText: '好的',
                    //     callback: () => {
                    //         this.$router.push({path: '/'})
                    //     },
                    // })
                }
                this.tab = index;
                // this.$router.push({path: path});
                // let jwt = localStorage.getItem('jwt')
                // if (jwt === null || jwt === undefined) {
                //     this.$emit('change_menu_action', {'from': 'home', 'type': 'show_login'})
                // } else {
                this.$router.push({path: '/chose_batch'})
                // }
            } else if (label === '高考资讯') {
                this.tab = index;
                this.$router.push({path: '/news'})
                //console.log('https://news.shudaozaixian.com/zixun/')
                //window.open("https://news.shudaozaixian.com/zixun/");
            } else {
                // if (label === '我的志愿') {
                //     let base_info = localStorage.getItem('base_info') || ''
                //     if (base_info !== null && base_info !== 'null' && base_info !== '[]' && base_info !== '' && base_info !== 'undefined') {
                //         this.tab = index;
                //         this.$router.push({path: path});
                //     } else {
                //         // ElMessageBox.alert('请登陆', '提醒', {
                //         //     confirmButtonClass: 'confirm_button_class',
                //         //     confirmButtonText: '好的',
                //         //     callback: () => {
                //         //         this.$router.push({path: '/'})
                //         //     },
                //         // })
                //         // return false
                //     }
                //
                // } else {
                this.tab = index;
                this.$router.push({path: path});
                // }
            }
            
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/style/var.scss";

.box {
    //width: calc(100% - $width-pdTotal);
    width: 100%;
    height: 50px;
    margin: 20px auto 0;
    box-shadow: 0px -5px 9px 0px #d0d0d040;
    
    .box_content {
        width: 1230px;
        height: 50px;
        margin: 0 auto;
        
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 18px;
        color: var(--font-1);
        
        .tabbar,
        .login {
            display: flex;
            align-items: center;
            height: 100%;
            
            .onbar,
            .bar {
                margin-right: 40px;
                padding: 0 2px;
                font-size: 18px;
                cursor: pointer;
                border-bottom: 2px solid transparent;
            }
            
            .onbar {
                color: var(--primary);
                border-color: var(--primary);
            }
            
            .onbar:hover,
            .bar:hover .sub_popup {
                display: flex
            }
        }
        
        .login {
            color: var(--font-3);
            
            div {
                cursor: pointer;
                
                &:hover {
                    color: var(--primary);
                }
            }
            
            span {
                margin: 0 10px;
            }
        }
    }
}

.popup_box {
    height: 50px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
}

.sub_popup {
    position: absolute;
    top: 35px;
    z-index: 333333;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    display: none;
    border-radius: 8px;
    background-color: rgba(51, 51, 51, 0.9);
    color: white;
    margin-top: 15px;
    padding: 20px 30px;
    font-size: 16px;
    
    .sub_popup_item {
        margin: 5px auto;
        white-space: nowrap;
    }
    
    .sub_popup_item:hover {
        color: #b5b5b5;
    }
}
</style>
